export default [
	{ label: 'Canillo' },
	{ label: 'Encamp' },
	{ label: 'Ordino' },
	{ label: 'La Massana' },
	{ label: 'Andorra la Vella' },
	{ label: 'Sant Julià de Lòria' },
	{ label: 'Escaldes-Engordany' },
	{ label: 'Interparroquial' },
];
