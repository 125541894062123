<template lang="html">
  <div class="word-counter">
		<textarea
			:placeholder="placeholder"
			:disabled="disabled"
			:value="value"
			:maxlength="max"
			@keyup="update" />
		<span class="word-counter__counter">{{ chars }} / {{ max }}</span>
	</div>
</template>

<script>
export default {
	name: 'WordCounter',
	props: {
		value: { type: String, default: '' },
		max: { type: Number, required: true },
		placeholder: { type: String, default: '' },
		disabled: { type: Boolean, default: false },
	},
	computed: {
		chars() {
			return this.value.length;
		},
	},
	methods: {
		update({ target: { value } }) {
			this.$emit('input', value);
		},
	},
};
</script>

<style lang="scss">
.word-counter {
	position: relative;
	height: 100px;

	textarea { height: 100%; }

	&__counter { position: absolute; }
}
</style>
