<template lang="html">
	<article class="step">
		<div class="grid">
			<div class="grid__col grid__col--6 grid">
				<div class="grid__col grid__col--9">
					<input
						type="text"
						v-model="details.name"
						:disabled="!isEditable"
						:placeholder="$t(`${sector}.details.placeholder.name`)" />
				</div>
				<div class="grid__col grid__col--3">
					<input
						type="number"
						v-model="details.year"
						:disabled="!isEditable"
						:placeholder="$t(`${sector}.details.placeholder.year`)"
						:min="minYear"
						:max="maxYear"
						step="1" />
				</div>
				<div class="grid__col grid__col--6">
					<dropdown-select
						:options="LOCATIONS"
						v-model="details.location"
						:disabled="!isEditable"
						:placeholder="$t(`${sector}.details.placeholder.location`)" />
				</div>
				<div class="grid__col grid__col--6">
					<dropdown-select
						:options="TYPES"
						v-model="details.type"
						:disabled="!isEditable"
						:placeholder="$t(`${sector}.details.placeholder.type`)" />
				</div>
				<div class="grid__col grid__col--4">
					<input
						type="number"
						v-model.number="details.cesi_code"
						:disabled="!isEditable"
						:placeholder="$t(`${sector}.details.placeholder.cesi_code`)"
						min="10000"
						max="79999" />
				</div>
				<div class="grid__col grid__col--4">
					<input
						type="number"
						v-model.number="details.surface"
						:disabled="!isEditable"
						:placeholder="$t(`${sector}.details.placeholder.surface`)"
						min="0" />
				</div>
				<div class="grid__col grid__col--4">
					<input
						type="number"
						v-model="details.residents"
						:disabled="!isEditable"
						:placeholder="$t(`${sector}.details.placeholder.residents`)"
						min="1" />
				</div>
				<div class="grid__col grid__col--12">
					<i18n path="residential.details.cesi_find_info" tag="p" class="note">
						<a
							href="https://www.mediambient.ad/images/stories/PDF/Instruccions_trobar_CESI.pdf"
							target="blank">
							{{ $t('residential.details.cesi_find_link') }}
						</a>
					</i18n>
				</div>
			</div>
			<div class="grid__col grid__col--6">
				<word-counter
					v-model="details.observations"
					:placeholder="$t(`${sector}.details.placeholder.observations`)"
					:disabled="!isEditable"
					:max="500" />
			</div>
		</div>
	</article>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import StepMixin from '@/mixins/StepMixin';
import DropdownSelect from '@/components/DropdownSelect.vue';
import WordCounter from '@/components/WordCounter.vue';
import { LOCATIONS, TYPES } from '@/assets/constants/residential';

export default {
	name: 'StepsDetails',
	mixins: [StepMixin],
	components: { DropdownSelect, WordCounter },
	data() {
		return {
			LOCATIONS: LOCATIONS.filter(({ label }) => label !== 'Interparroquial'),
			TYPES,
			minYear: 2000,
			maxYear: new Date().getFullYear(),
		};
	},
	computed: {
		...mapState(['report']),
		details: {
			get() { return this.report.details; },
			set(details) { this.setDetails(details); },
		},
		isEditable() { return this.report.editable; },
	},
	watch: {
		details: {
			deep: true,
			immediate: true,
			/* eslint-disable camelcase */
			handler({ name, year, location, type, cesi_code }) {
				const valid = !!name && !!location && !!type
					&& year >= this.minYear && year <= this.maxYear
					&& (!cesi_code || (cesi_code >= 10000 && cesi_code < 80000));
				this.$emit('validate', valid);
			},
			/* eslint-enable camelcase */
		},
	},
	methods: mapMutations(['setDetails']),
};
</script>
